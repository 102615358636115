body {
  margin: 0;
}

.map {
  height:100vh;
}

.leaflet-control-layers {
  border-radius: 5px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(assets/images/map-layers.webp);
}

.leaflet-control-layers-toggle {
  background-image: url(assets/images/map-layers.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 26px;
  height: 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 26px;
  height: 26px;
}
